import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import { fetchUser } from "../../services";
import MSG, { notifyError } from "../../utils/Helper";

class DonwloadQuestionPaper extends Component {
  state = {
    showPopUp: false,
    checked: false,
    downloadAllowed: false,
  };
  componentWillReceiveProps(nextProps) {
    this.setState({
      showPopUp: nextProps.showDownloadPopUp,
      downloadAllowed: nextProps.downloadAllowed,
    });
  }

  handleisRequiredClick = (event) => {
    const val = event.target.checked;
    this.setState({ checked: val });
  };

  handleClosePopup = () => {
    this.props.closeDownloadPopUp();
    this.setState({
      showPopUp: false,
      checked: false,
    });
  };

  handleDownloadFile = async (type) => {
    this.handleClosePopup();
    if (this.state.checked) {
      await this.props.downloadQuestionPaper(type, 1);
    } else {
      await this.props.downloadQuestionPaper(type);
    }
    try {
      await this.props.dispatch(fetchUser())
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
  };

  render() {
    let is_new_pricing =
      !_.isEmpty(this.props.user) &&
      this.props.user.plan_details.is_new_pricing;

      if(this.props.isDemo){
        is_new_pricing = 1;
      }
    return (
      <>
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showPopUp}
          showCloseButton
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">Choose your mode of document</p>
              <div class="d-flex justify-content-center align-items-center mb-3">
                <span className="switch-download-text">
                  Download with answer key?
                </span>
                <label
                  style={{ marginLeft: "1rem", marginBottom: "0" }}
                  class="switch-download"
                >
                  <input
                    onChange={this.handleisRequiredClick}
                    type="checkbox"
                  />
                  <small
                  ></small>
                </label>
              </div>
              <div
                style={{ padding: "0 1rem" }}
                className="d-flex justify-content-around"
              >
                <button
                  name="pdf"
                  onClick={() =>
                    this.props.isDemo
                      ? this.props.setDemoModal()
                      : this.handleDownloadFile("pdf")
                  }
                  className="btn btn-sm download-question-btn"
                >
                  .pdf
                </button>
                <button
                  name="xlsx"
                  onClick={() =>
                    this.props.isDemo
                      ? this.props.setDemoModal()
                      : this.handleDownloadFile("xlsx")
                  }
                  className={`btn btn-sm download-question-btn`}
                >
                  .xlsx
                </button>
                <button
                  name="json"
                  // disabled={!this.state.downloadAllowed?.plan_details?.download_doc_is_allowed}
                  onClick={() =>
                    this.props.isDemo
                      ? this.props.setDemoModal()
                      : this.handleDownloadFile("json")
                  }
                  className={`btn btn-sm download-question-btn`}
                >
                  .json
                  {/* {is_new_pricing === 1 && (
                    <img
                      src="img/crown.jpg"
                      alt="icon"
                      width="30"
                      height="30"
                      style={{
                        position: "absolute",
                        marginTop: "-25px",
                        marginLeft: "19px",
                      }}
                    />
                  )} */}
                </button>
                <button
                  name="docx"
                  onClick={() =>
                    this.props.isDemo
                      ? this.props.setDemoModal()
                      : this.handleDownloadFile("docx")
                  }
                  className={`btn btn-sm download-question-btn`}
                >
                  .docx
                </button>
              </div>
            </div>
          }
          onEscapeKey={this.handleClosePopup}
          onOutsideClick={this.handleClosePopup}
          onConfirm={this.handleClosePopup}
          onCancel={this.handleClosePopup}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(withRouter(DonwloadQuestionPaper));
