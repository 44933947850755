import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import { saveAs } from "file-saver";
import {
  fetchUser,
  QuestionPaperRating,
  updateUserDetails,
  SATGenerateQuestions,
  validationForDownloadQuePaper,
  satDownloadQuestionPaper,
  newSatDownloadQuestionPaper,
  createTest,
  getRequestsInQueue,
} from "../services";
import MSG, {
  notifySuccess,
  notifyError,
  profession,
  findUsList,
} from "../utils/Helper";
import {
  closeReferral,
  setInitialQues,
  showReferral,
  startLoader,
  stopLoader,
} from "../store/reducers/generalActions";
import { authLogout } from "../store/reducers/auth";
import "react-dropzone-uploader/dist/styles.css";
import { isMobile } from "react-device-detect";
import ReferPopup from "./common/ReferPopup";
import ReactSelect, { components } from "react-select";
import ToggleForGQ from "./common/ToggleForGQ";
import { Button } from "@mui/material";
import TakeAssessment from "./common/TakeAssessment";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

class SubjectExpertiseQuestions extends Component {
  state = {
    subject: [
      // {value: "SAT", label: "Pencil-Paper SAT"},
      { value: "New SAT Format", label: "Digital SAT" },
    ],
    selectedQuesType: "reading",
    topic: [{ value: "", label: "" }],
    buttonType: "Easy",
    paperTopic: "sat_ques_paper",
    sat_key: 1,
    questionGenerated: false,
    level_pcma: "easy level",
    questionPaperGenerated: false,
    subject_name: { value: "DIGITAL SAT", label: "DIGITAL SAT" },
    disableTopicFormula: false,
    titleofQuestion: this.props.defaultContent?.title,
    titleofQuestion_emsg: "",
    contentForQuestions: this.props.defaultContent?.content,
    wikiSearchInput: "",
    refreshing: false,
    typing: false,
    typingTimeout: 0,
    selectedWikiTopic: null,
    tmpselectedWikiTopic: "",
    showAlert: false,
    currentActiveTab: 2,
    questions: [],
    feedbackAlert: false,
    showRatingAlert: false,
    questionPaperRating: null,
    timeLeft: {},
    highlightBadEmoji: null,
    highlightNeutralEmoji: null,
    highlightGoodEmoji: null,
    showPremiumPrompt: false,
    premiumPromptText: "",
    isShowPremiumPrompt: false,
    totalPages: 0,
    pageNo: 1,
    requiredQuestionCount: 11110,
    lessQuestionsGenerated: false,
    moreQuestionsGenerated: false,
    showPopup: false,
    firstEventScroll: false,
    activity_count: 0,
    isQuestionGenerated: false,
    source: "Direct",
    scrollAfterGenerate: false,
    leavePageEvent: false,
    showUpdateProfilePopUp: false,
    source_from: "",
    source_from_other: "",
    profession: "",
    profession_other: "",
    data: {
      contact_number: "",
      country_code: "in",
      dial_code: "",
      formatted_phone_number: "",
    },
    showLoader: false,
    showFeedbackForm: true,
    ratingComment: "",
    showWordLimit: false,
    isCustomPages: false,
    totalQuestionsFormed: 0,
    showConductTestDropdown: false,
    showDurationAlert: false,
    content_id: null,
    default_timing: {
      seconds: 0,
      timeText: "00:00",
    },
  };

  handleShowConductTestDropdown = () => {
    this.setState({
      showConductTestDropdown: !this.state.showConductTestDropdown,
    });
  };

  calculateTimeLeft = () => {
    // Set the date we're counting down to
    const countDownDate = new Date("Oct 31, 2022 23:59:59").getTime();

    // get today's date
    const today = new Date().getTime();
    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.handleGenerateQuestion();
  };

  isFutureDate = (idate) => {
    var today = new Date().getTime(),
      idate = idate.split("/");

    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    return today - idate < 0 ? true : false;
  };

  async componentDidMount() {
    let default_timing = 4200;
    let timeText = "01:10";
    this.setState({
      default_timing: {
        seconds: default_timing,
        timeText: timeText,
      },
    });
  }

  componentDidUpdate() {
    window.onclick = () => {
      if (!this.state.firstEventScroll && this.state.activity_count === 1) {
        //trackEvent(("First Activity - Click");
      }
    };

    window.onscroll = (e) => {
      // console.log("Scroll");
      if (
        !this.state.firstEventScroll &&
        this.state.activity_count === 0 &&
        this.state.source === "Direct"
      ) {
        //trackEvent(("First Activity - Scroll");
        this.setState({
          firstEventScroll: true,
        });
      }

      if (this.state.scrollAfterGenerate) {
        //trackEvent(("Scroll After Question Generation");
        this.setState({
          scrollAfterGenerate: false,
        });
      }
      // Detect user scroll to the bottom of the page.
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const element = document.getElementById("generated_questions");
        if (element && this.state.source === "Direct") {
          //trackEvent(("Scroll Down To Bottom - Generate Question");
        }
      }
    };

    if (this.props.isAuthenticated) {
      const refData = localStorage.getItem("refData");
      if (refData) {
        this.props.history.replace("/plans");
      }
    }
  }

  componentWillUnmount() {
    window.onscroll = () => {};
    window.onclick = () => {};
  }

  tabChanged = (e) => {
    this.props.dispatch(setInitialQues());
    this.setState({ currentActiveTab: e });
  };

  redirectToPlansPage = () => {
    this.props.history.push("/plans-bloom");
  };

  onInputChange = (e) => {
    this.setState({ tmpselectedWikiTopic: e.target.value });
  };

  scrollToQuestions = () => {
    const position = document.getElementById("generated_questions").offsetTop;
    window.scrollTo(0, position);
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  downloadQuestionPaper = async (is_qp_generation = false) => {
    let inputFiletype = "pdf";
    let fileName = this.state.paperTopic + ".pdf";
    let mimeType = "application/pdf";

    const content_id = this.state.content_id;
    const is_new_sat = true;

    const isSat = 2;

    this.props.dispatch(startLoader());
    const { data: response } = await validationForDownloadQuePaper({
      content_id,
      is_sat: isSat,
    });

    if (response.success === true) {
      /*if (type === "pdf") { */
      if (response.code === 1111) {
        this.props.dispatch(fetchUser());
      }
      try {
        let res = {};
        let req = {
          content_id,
          is_qp_generation,
          filetype: inputFiletype,
        };

        if (isSat === 2) {
          res = await newSatDownloadQuestionPaper(req);
        } else {
          res = await satDownloadQuestionPaper(req);
        }

        if (res.status !== 200) {
          notifyError(res.message);
        } else if (is_qp_generation) {
          this.setState({
            questionGenerated: true,
          });
        } else {
          var blob = new Blob([res.data], { type: mimeType });
          saveAs(blob, fileName);
        }
      } catch (err) {
        console.log(err);
        notifyError(MSG.commonApiError);
      }
    } else {
      notifyError(response.message);
    }
    this.props.dispatch(stopLoader());
  };

  handleGenerateQuestion = async (e) => {
    this.props.dispatch(startLoader());
    let device = "Desktop";
    if (isMobile === true) {
      device = "Mobile";
    }

    // check request in queue
    try {
      const { data: res } = await getRequestsInQueue();
      if (res.success === true) {
        var requestsInQueue = res.response.requestsInQueue;
        if (requestsInQueue > 2) {
          notifyError(
            "We're facing a heavy traffic. Please wait until they are processed before generating new questions. Thank you!"
          );
          this.props.dispatch(stopLoader());
          return;
        }
      }
    } catch (err) {
      console.log(err);
      this.props.dispatch(stopLoader());
    }

    const sat_key = this.state.sat_key;
    const is_new_sat = true;
    const req = {
      device,
      sat_key,
      is_new_sat,
    };
    try {
      const { data: res } = await SATGenerateQuestions(req);
      console.log(res);
      if (res.success === true) {
        if (!_.isEmpty(res.response)) {
          const content_id = res.response.content_id;
          const topic = res.response.topic;
          this.setState(
            {
              content_id,
              paperTopic: topic,
            },
            () => this.downloadQuestionPaper(true)
          );
        }
      } else {
        notifyError(res.message);
        this.props.dispatch(stopLoader());
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
      this.props.dispatch(stopLoader());
    }
    // this.props.dispatch(stopLoader());
  };

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.state.showWordLimit) this.setState({ showWordLimit: false });
    this.setState({
      [input.name]: input.value,
    });
  };

  handleQPRating = async () => {
    if (this.state.questionPaperRating === null) {
      notifyError("Please Rate Question Paper First!");
      return false;
    }
    this.setState({ showRatingAlert: false, feedbackAlert: false });

    var QP_Rating_Parm = {};

    var content_id = this.state.questions[0].content_id;
    if (this.state.ratingComment !== "") {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
        comment: this.state.ratingComment,
      };
    } else {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
      };
    }
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await QuestionPaperRating(QP_Rating_Parm);
      if (res.success === true) {
        notifySuccess(res.message);
        this.setState({
          questionPaperRating: null,
          highlightBadEmoji: null,
          highlightNeutralEmoji: null,
          highlightGoodEmoji: null,
          showFeedbackForm: false,
        });
        this.handleRatingPromptCloseEvent();
        //this.setState({ showRatingAlert: false, feedbackAlert: false });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  handleQPRatingChange = (value) => {
    this.setState({
      questionPaperRating: value,
    });
  };

  getRateText = (rate) => {
    if (rate === 1) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Bad";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate === 2) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Neutral";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate === 3) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Good";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else {
      document.getElementById("labelFeedbackLabel").innerHTML = "";
      document.getElementById("labelFeedbackLabel").style.width = "0";
    }
  };

  handleRatingPromptCloseEvent = () => {
    this.setState({ showRatingAlert: false, feedbackAlert: false });
    if (this.state.isShowPremiumPrompt) {
      this.setState({
        showPremiumPrompt: true,
      });
    }
  };

  // For testing Referral Popup
  handlePopupOpen = () => {
    this.props.dispatch(
      showReferral({
        title: "Want to extend your plan for free?",
        content: "Invite your friends and get 7 days of Premium Plan added",
      })
    );
  };

  handleClose = () => {
    this.props.dispatch(closeReferral());
  };

  updateActivityCount = (e) => {
    if (e.target.className === "dzu-input") {
      var increment = this.state.activity_count + 1;
      this.setState({
        activity_count: increment,
      });
    }
  };

  handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      this.setState({
        data: {
          ...this.state.data,
          contact_number: value,
          formatted_phone_number: formattedValue,
          country_code: data.countryCode,
          dial_code: "+" + data.dialCode,
        },
      });
    }
  };

  handleUpdateProfileChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };

  UpdateProfileSubmit = async () => {
    let req = {};
    if (this.props.user.update_profile_index === 1) {
      if (this.state.profession === "") {
        notifyError("Please select a option");
        return;
      }
      if (
        this.state.profession === "Others" &&
        this.state.profession_other === ""
      ) {
        notifyError("Please enter your profession");
        return;
      }

      req = {
        profession: this.state.profession,
        profession_other: this.state.profession_other,
      };
    } else if (this.props.user.update_profile_index === 2) {
      if (this.state.source_from === "") {
        notifyError("Please select a option");
        return;
      }
      if (
        this.state.source_from === "Others" &&
        this.state.source_from_other === ""
      ) {
        notifyError("Please enter your profession");
        return;
      }

      req = {
        source_from: this.state.source_from,
        source_from_other: this.state.source_from_other,
      };
    } else if (this.props.user.update_profile_index === 3) {
      if (this.state.data.contact_number === "") {
        notifyError("Please enter your phone details");
        return;
      }

      var reqObj = this.state.data;
      req = { ...reqObj };
      req.contact_number = req.contact_number.substring(
        req.dial_code.length - 1
      );
    }

    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails(req);
      if (res.success === true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index === 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  OpenReviewPopUp = () => {
    this.setState({
      showRatingAlert: true,
    });
  };

  handleRatingComment = (e) => {
    this.setState({
      ratingComment: e.target.value,
    });
  };

  setSubject = (subject) => {
    this.setState({
      subject_name: subject,
    });
  };

  radioButtonValue = (event) => {
    const { name } = event.target;
    console.log(name);
    let default_timing = 0;
    let timeText = "";
    let sat_key = 1;
    if (name === "reading") {
      sat_key = 2;
      default_timing = 1920;
      timeText = "00:32";
    } else if (name === "maths") {
      sat_key = 3;
      default_timing = 2100;
      timeText = "00:35";
    } else if (name === "complete") {
      sat_key = 4;
      default_timing = 4200;
      timeText = "01:10";
    }
    this.setState({
      selectedQuesType: name,
      sat_key,
      default_timing: {
        seconds: default_timing,
        timeText: timeText,
      },
    });
  };

  redirectToSATTestPreview = () => {
    this.props.history.push("/digital-sat-test-preview");
  };

  handleCreateTest = async () => {
    if (!this.props.user.plan_details.is_group_assessment) {
      return;
    }
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await createTest({
        content_id: this.state.content_id,
      });
      if (res.success === true) {
        notifySuccess(res.message);

        // var redirect_to = "/test-preview";
        var redirect_to = "/digital-sat-test-preview";

        this.props.history.push({
          pathname: redirect_to,
          state: {
            content_id: this.state.content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };

  beforeAssessment = () => {
    if (!this.props.user.plan_details.is_self_assessment) {
      return;
    }
    this.setState({
      questionGenerated: false,
      showDurationAlert: true,
    });
  };

  callbackFunc = (topic, show) => {
    this.setState({
      showDurationAlert: false,
      questionGenerated: true,
      paperTopic: topic,
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className="height-cover bg-e5">
          <div className="bg--overlay--top"></div>
          <div
            className={classNames(
              "container",
              styles["demo-custom-margin"],
              "mb-4 position-relative"
            )}
            style={{ marginTop: "50px" }}
          >
            <div
              className={classNames(
                styles["generate--bg--title"],
                "generate--bg--title"
              )}
            >
              <h3>Generate Standard Test Papers</h3>
              <span>Based on Exam's Standard Blueprint</span>
            </div>
            <ToggleForGQ
              tab="Competitive"
              credits={this.props.user.credits}
              plan_details={this.props.plan_details}
            />
            <div className="question-generate-tab-outer maxw-820">
              <div className="">
                <label
                  for="selectques_type"
                  className="form-label d-flex align-items-center"
                  style={{
                    fontWeight: 500,
                    color: "#344054",
                    gap: "1rem",
                  }}
                >
                  {" "}
                  Select Exam{" "}
                </label>
                <div className=" text-left">
                  <ReactSelect
                    hideSelectedOptions={false}
                    maxMenuHeight={200}
                    components={{
                      Option,
                    }}
                    options={this.state.subject}
                    type="text"
                    defaultValue={this.state.subject[0]}
                    id="selectques_type"
                    disabled={true}
                    value={this.state.subject[0]}
                    styles={this.state.customStyles}
                  />
                </div>
                {this.state.errorEvent === "subject_name" && (
                  <span className="error-msg-expertise">
                    {this.state.errorMsg}
                  </span>
                )}
              </div>
              <div className="mt-3">
                <label
                  for="selectques_type"
                  className="form-label d-flex align-items-center"
                  style={{
                    fontWeight: 500,
                    color: "#344054",
                    gap: "1rem",
                  }}
                >
                  {" "}
                  Select Preferred Test Section{" "}
                </label>
                <div
                  style={{ gap: "1rem" }}
                  className="d-flex text-left flex-column"
                >
                  <label className="mordern-radiobox-label my-0">
                    <input
                      name="reading"
                      type="radio"
                      disabled={this.state.disableTopicFormula}
                      checked={this.state.selectedQuesType === "reading"}
                      onChange={(e) => this.radioButtonValue(e)}
                    />
                    <span style={{ top: "1px" }} className="design"></span>{" "}
                    {this.state.subject_name.value === "SAT"
                      ? "Reading"
                      : "Reading/Writing"}
                  </label>
                  {this.state.subject_name.value === "SAT" && (
                    <label
                      style={
                        this.state.disableTopicFormula
                          ? { opacity: "0.7", cursor: "no-drop" }
                          : null
                      }
                      className="mordern-radiobox-label my-0"
                    >
                      <input
                        name="writing"
                        type="radio"
                        disabled={this.state.disableTopicFormula}
                        checked={this.state.selectedQuesType === "writing"}
                        onChange={(e) => this.radioButtonValue(e)}
                      />
                      <span style={{ top: "1px" }} className="design"></span>{" "}
                      Writing
                    </label>
                  )}
                  <label
                    style={
                      this.state.disableTopicFormula
                        ? { opacity: "0.7", cursor: "no-drop" }
                        : null
                    }
                    className="mordern-radiobox-label my-0"
                  >
                    <input
                      name="maths"
                      type="radio"
                      disabled={this.state.disableTopicFormula}
                      checked={this.state.selectedQuesType === "maths"}
                      onChange={(e) => this.radioButtonValue(e)}
                    />
                    <span style={{ top: "1px" }} className="design"></span>{" "}
                    Maths
                  </label>
                  <label
                    style={
                      this.state.disableTopicFormula
                        ? { opacity: "0.7", cursor: "no-drop" }
                        : null
                    }
                    className="mordern-radiobox-label my-0"
                  >
                    <input
                      name="complete"
                      type="radio"
                      disabled={this.state.disableTopicFormula}
                      checked={this.state.selectedQuesType === "complete"}
                      onChange={(e) => this.radioButtonValue(e)}
                    />
                    <span style={{ top: "1px" }} className="design"></span> I
                    want complete paper
                  </label>
                </div>
                {this.state.errorEvent === "topic_or_formula" && (
                  <span className="error-msg-expertise">
                    {this.state.errorMsg}
                  </span>
                )}
              </div>

              <div className="btn-flex100 mt-5">
                <button
                  onClick={this.handleGenerateQuestion}
                  type="submit"
                  className="btn--submit"
                >
                  Generate Mock Test
                </button>
              </div>
            </div>
          </div>
        </div>

        <TakeAssessment
          content_id={this.state.content_id}
          currentQnTypeSatCheck={2}
          default_timing={this.state.default_timing}
          topic_name={this.state.paperTopic}
          show={this.state.showDurationAlert}
          callBack={this.callbackFunc}
          history={this.props.history}
        />

        <SweetAlert
          show={this.state.showRatingAlert}
          showConfirm
          showCloseButton
          style={{ zIndex: "999 !important" }}
          title={
            <>
              <div className="swal-titile">
                <h4>
                  <span>Your Question Paper is being Generated.</span>
                  <br />
                  <span>
                    Meanwhile, what do you think of your recent Question Paper?{" "}
                  </span>
                </h4>
              </div>
              <div className="rate--question large-star1 mt-4">
                <span className="feedbackLabel">
                  <span id="labelFeedbackLabel"></span>
                </span>
                <div className="line-height-0 d-flex justify-content-center pt-4">
                  <div id="bad" className="emoji-scale">
                    <span className="describeEmoji">Bad</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating === 1 ? "fas" : "far"
                      } fa-frown highlight ${this.state.highlightBadEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 1,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: null,
                          highlightBadEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="neutral" className="emoji-scale">
                    <span className="describeEmoji">Neutral</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating === 2 ? "fas" : "far"
                      } fa-meh highlight ${this.state.highlightNeutralEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 2,
                          highlightBadEmoji: null,
                          highlightGoodEmoji: null,
                          highlightNeutralEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                  <div id="Good" className="emoji-scale">
                    <span className="describeEmoji">Good</span>
                    <i
                      className={`emoji ${
                        this.state.questionPaperRating === 3 ? "fas" : "far"
                      } fa-grin-alt highlight ${this.state.highlightGoodEmoji}`}
                      onClick={() =>
                        this.setState({
                          questionPaperRating: 3,
                          highlightBadEmoji: null,
                          highlightNeutralEmoji: null,
                          highlightGoodEmoji: "highlight-emoji",
                        })
                      }
                    ></i>
                  </div>
                </div>
              </div>
            </>
          }
          confirmBtnText="Submit"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={this.handleRatingPromptCloseEvent}
          onOutsideClick={this.handleRatingPromptCloseEvent}
          onConfirm={this.handleQPRating}
          onCancel={this.handleRatingPromptCloseEvent}
        ></SweetAlert>

        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showPremiumPrompt}
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text">{this.state.premiumPromptText}</p>
              <Link className="btn prompt-plan-btn" to="/plans">
                Upgrade Plan
              </Link>
              <span style={{ margin: "20px", fontSize: "20px" }}>OR</span>
              <Link className="btn prompt-referral-btn" to="/refer-prepai">
                Refer PrepAI
              </Link>
            </div>
          }
          onEscapeKey={() => this.setState({ showPremiumPrompt: false })}
          onOutsideClick={() => this.setState({ showPremiumPrompt: false })}
          onConfirm={() => this.setState({ showPremiumPrompt: false })}
          onCancel={() => this.setState({ showPremiumPrompt: false })}
        />
        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showUpdateProfilePopUp}
          showConfirm
          title="Please complete your profile to continue"
          confirmBtnText={
            this.props.user.update_profile_index === 3 ? "Finish" : "Continue"
          }
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showUpdateProfilePopUp: false })}
          onOutsideClick={() =>
            this.setState({ showUpdateProfilePopUp: false })
          }
          onConfirm={this.UpdateProfileSubmit}
          onCancel={() => this.setState({ showUpdateProfilePopUp: false })}
          style={{ overflow: "initial", marginTop: "150px" }}
        >
          <form>
            {this.props.user.update_profile_index === 1 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  What best describes your role?*
                </label>

                <div
                  className="select-list"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <select
                    name="profession"
                    className="source_from"
                    required
                    value={this.state.profession}
                    onChange={this.handleUpdateProfileChange}
                  >
                    <option value="">Select</option>
                    {profession().map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}{" "}
            {this.props.user.update_profile_index === 2 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  How did you discover PrepAI?*
                </label>
                <div
                  className="select-list"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <select
                    name="source_from"
                    className="source_from"
                    required
                    onChange={this.handleUpdateProfileChange}
                    value={this.state.source_from}
                    disabled={this.state.referralActive}
                  >
                    <option value="">Select</option>

                    {findUsList().map((op) => {
                      return (
                        <option key={op} value={op}>
                          {op}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
            {this.props.user.update_profile_index === 3 && (
              <div
                className="d-flex justify-content-start"
                style={{
                  marginBottom: "0",
                  marginTop: "18px",
                  marginLeft: "98px",
                }}
              >
                <label className="form--label">
                  Update your contact number*
                </label>
                <div
                  className="position-relative"
                  style={{ marginLeft: "35px", fontSize: "larger" }}
                >
                  <PhoneInput
                    country={this.state.data.country_code}
                    countryCodeEditable={false}
                    placeholder="Enter phone number"
                    name="formatted_phone_number"
                    value={this.state.data.formatted_phone_number}
                    onChange={this.handleOnChange}
                    buttonStyle={{ backgroundColor: "transparent" }}
                  />
                </div>
              </div>
            )}
            <div
              style={{
                position: "relative",
                marginLeft: "98px",
                marginTop: "18px",
              }}
            >
              {this.state.source_from === "Others" &&
                this.props.user.update_profile_index === 2 && (
                  <div
                    className="form-group form-input"
                    // style={{ position: "absolute", left: "0", top: "-35px" }}
                  >
                    <label htmlFor="source_from_other" className="form--label">
                      Please Specify*
                    </label>
                    <input
                      type="text"
                      name="source_from_other"
                      id="source_from_other"
                      value={this.state.source_from_other}
                      onChange={this.handleChange}
                      required
                      style={{
                        marginLeft: "35px",
                        fontSize: "larger",
                      }}
                    />
                  </div>
                )}

              {this.state.profession === "Others" &&
                this.props.user.update_profile_index === 1 && (
                  <div
                    className="form-group form-input"
                    // style={{
                    //   marginLeft:
                    //     this.state.source_from === "Others" && "17px",
                    //   position: "absolute",
                    //   right: "5px",
                    //   top: "-35px",
                    // }}
                  >
                    <label htmlFor="profession_other" className="form--label">
                      Please Specify*
                    </label>
                    <input
                      type="text"
                      name="profession_other"
                      id="profession_other"
                      value={this.state.profession_other}
                      onChange={this.handleChange}
                      required
                      style={{
                        marginLeft: "35px",
                        fontSize: "larger",
                      }}
                    />
                  </div>
                )}
            </div>
          </form>
          {this.state.showLoader ? (
            <div className="spinner-border mt-3" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        <SweetAlert
          customClass="sweet-alert-sat"
          style={{ zIndex: "1000" }}
          // show={tru	e}
          show={this.state.questionGenerated}
          showConfirm={false}
          title={
            <div className="swal-title">
              <p className="style-text"> Test Paper Generated Successfully! </p>
              <div className="sat-document-parent">
                <img
                  className="sat-document"
                  src={`/img/question_paper_sat.png`}
                  alt="document"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => {
                    this.downloadQuestionPaper(false);
                  }}
                  style={{ background: "#49c0a0" }}
                >
                  Download Paper
                </Button>
                <Button
                  variant="contained"
                  style={{ background: "#49c0a0", position: "relative" }}
                  className="btn dropdown-toggle dropdown-toggle-split"
                  id="dropdownMenuReference"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-reference="parent"
                  onClick={() => {
                    this.handleShowConductTestDropdown();
                  }}
                >
                  Conduct Test
                  {this.state.showConductTestDropdown && (
                    <div
                      className="clq-dropdown-menu"
                      aria-labelledby="dropdownMenuReference"
                    >
                      <a
                        className={`dropdown-item `}
                        onClick={() => {
                          this.handleShowConductTestDropdown();
                          this.beforeAssessment();
                        }}
                      >
                        For Self
                      </a>
                      <a
                        className={`dropdown-item `}
                        onClick={() => {
                          this.handleShowConductTestDropdown();
                          this.handleCreateTest();
                        }}
                      >
                        Others
                      </a>
                    </div>
                  )}
                </Button>
              </div>
            </div>
          }
          onEscapeKey={() => {
            this.setState({
              questionGenerated: false,
            });
          }}
          onOutsideClick={() => {
            this.setState({
              questionGenerated: false,
            });
          }}
          onConfirm={() => {
            this.setState({
              questionGenerated: false,
            });
          }}
          onCancel={() => {
            this.setState({
              questionGenerated: false,
            });
          }}
        />

        <ReferPopup />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    showPopup: state.generalActions.showPopUp,
    defaultContent: state.generalActions.defaultContent,
    customQuesType: state.generalActions,
  };
};

export default connect(mapStateToProps)(SubjectExpertiseQuestions);
