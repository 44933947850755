import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import "./appstyle.css";
import ReduxToastr from "react-redux-toastr";
import { authCheck } from "./store/reducers/auth";
import { stopLoader } from "./store/reducers/generalActions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();
store.dispatch(authCheck());
if (window.performance) {
  store.dispatch(stopLoader());
}

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>

      <ReduxToastr
        timeOut={4000}
        newestOnTop={true}
        preventDuplicates
        position="top-right"
        getState={(state) => state.toastr} // This is the default
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
      />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
