import React, { Component } from "react";
import { connect } from "react-redux";
import { Prompt } from "react-router";
import _ from "lodash";
import produce from "immer";
import { Link } from "react-router-dom";

import SweetAlert from "react-bootstrap-sweetalert";

import {
  fetchUser,
  QuestionPaperRating,
  addPagination,
  updateUserDetails,
  createTest,
  purchaseAddOns,
} from "./../services";
import MSG, { notifySuccess, notifyError } from "./../utils/Helper";
import {
  closeReferral,
  setInitialQues,
  showDefaultContent,
  showReferral,
  startLoader,
  stopLoader,
  setSelectedPlan,
} from "./../store/reducers/generalActions";
import { authLogout } from "./../store/reducers/auth";
import BloomsTexanomy from "./questionTypes/bloomsTexanomy";
import Tooltip from "./common/tooltip";
import Addeasymcq from "./addquestion/addeasymcq";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import Addmediummcq from "./addquestion/addmediummcq";
import Addhardmcq from "./addquestion/addhardmcq";
import Truefalse from "./addquestion/true_false";
import AddDescriptive from "./addquestion/adddescriptive";
import Fillups from "./addquestion/fillups";
import { data } from "jquery";
import { isMobile } from "react-device-detect";
import Filter from "./common/Filter";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import {
  FormatAlignJustify,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

import ToggleForGQ from "./common/ToggleForGQDemo";
import {
  Elements,
  ElementsConsumer,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./common/AddOnsCheckoutForm";
import QuestionPaperTopUp from "./common/QuestionPaperTopUp";
import textData from "./common/textData";
import Salebar from "./sales";
import DonwloadQuestionPaper from "./common/DonwloadQuestionPaper";
import QuestionPaperPreviewBloom from "./question-paper-preview-bloom";
import DemoHeader from "./common/DemoHeader";
import DemoGenerateQuestionsStdHots from "./common/DemoGenerateQuestionStdHots";

import styles from "../styles/demoResponsive.module.css";
import classNames from "classnames";

const InjectedCheckoutForm = (props, data) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        {...props}
        data={data}
      />
    )}
  </ElementsConsumer>
);

class QuestionPaperGenerateBloom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionPaperGenerated: false,
      titleofQuestion: "Nelson Mandela - An Inspiration of Many",
      titleofQuestion_emsg: "",
      contentForQuestions: `Personal Background - \nNelson Mandela was born in 1918, in Mvezo in the Transkei. This part of his life was difficult as his father, Chief Henry Mbolestanandure, died when he was nine years old. His mother, Nonhi discovered that her husband passed away, but she could not tell her son. ANC (African National Congress), who at that time operated in secrecy, was also banned from organizing and holding meetings in South Africa. This ban forced all members of ANC to be underground; thus, Nonhi had to hide the existence of her husband. At the age of fourteen, Nelson Mandela left for Fort Hare, a college connected to the University of Cape Town. He left his home immediately after completing his studies, as he was informed that his mother was very ill and wanted to see her. On his way, he was arrested by the police, who suspected him of being a member of ANC. He had to spend time in prison, as he was humiliated and beaten, but he never gave up his connection to ANC and never told the police about the activities of the organization. A year later, he was released, and with help of his fellow workers, he was received by one of his relatives in Johannesburg. He was 25 years old at that time, but he did not have a job, a shelter, or a home. At that time, he was interested in the legal system, but soon he noticed that the rights enjoyed by white people were impossible to be given to black people. The struggle of the majority of people in South Africa who were black was probably the crucial point in Mandela’s life. In it, he mastered and improved his leadership skills, as well as his political knowledge, and also his skills of organization and planning.
  \nMandela’s impact on others - \nMandela’s struggle and achievements left an impression on thousands of people who consider him a hero. One of the people who considered him to be a great leader is President Barack Obama. He supported the struggle of ANC and wrote his own biography about the life of the South African leader. Mandela has influenced many individuals through his fight for democracy. His struggle has made him one of the most influential political activists, at the same time impressing thousands of people. A person who could fight for the rights of others, even though he was imprisoned for decades, is a positive role model for all generations. Throughout his life, he fought for different causes, but the most important one was democracy and the end of apartheid. He managed to make deals and cooperate with the white leaders and thus was able to take the power by political means. Mandela’s influence on the world has to do with the fact that he fought for something and achieved the result. \n\nConclusion - \nMandela’s life inspires many people. He was brave and strong, but he was also wise and did not seek revenge. His struggle against apartheid was organized and planned. He cooperated with the white leaders, although he urged them to change the situation in the country when he was elected as the President. He has inspired millions of people, and because of his idealistic principles, he became a hero to many. Thanks to the ideas and activities of Nelson Mandela, democracy was created in South Africa. Mandela’s battles for his own rights and the rights of other people showed his strong character. His ideas of cooperation with the white and the goal to defeat apartheid made him a great leader and politician. His activity in prisons inspired many prisoners, and he ultimately won the battle against apartheid. He has been a great mentor and leader, and thanks to that, he was a great man and worth of such a title.
      `,
      newUser: this.props.defaultContent?.hotsFlag,
      wikiSearchInput: "",
      refreshing: false,
      typing: false,
      typingTimeout: 0,
      selectedWikiTopic: null,
      tmpselectedWikiTopic: "",
      showAlert: false,
      wikiContent: "",
      searchTopic: "",
      iseditpaper: true,
      currentActiveTab: 2,
      questions: [],
      ques_count: {
        easyCount: 0,
        mediumCount: 0,
        hardCount: 0,
        descriptiveCount: 0,
        trueFalseCount: 0,
        fillUpsCount: 0,
      },
      addquestionmodal: false,
      questiontypetab: "knowledge",
      defaultActiveTab: "custom-tab",
      addeasymcq: false,
      addmediummcq: false,
      addhardmcq: false,
      addtruefalse: false,
      adddescriptive: false,
      addfillups: false,
      sortableQuestionOption: [
        {
          id: 1,
          name: "An object at rest stays at rest and an object in motion stays in motion.",
        },
        {
          id: 2,
          name: "Individuals define physics by what it was rather than what it is and will be.",
        },
        {
          id: 3,
          name: "What we mean by this is that things keep changing in the world of physics with every discovery.",
        },
        {
          id: 4,
          name: "As theories progress and discoveries are made, not only the answer but the whole question.",
        },
      ],
      isUploaded: false,
      videoFile: null,
      pdfDocFile: null,
      feedbackAlert: false,
      showRatingAlert: false,
      callRatingAPI: true,
      questionPaperId: null,
      questionPaperRating: null,
      timeLeft: {},
      highlightBadEmoji: null,
      highlightNeutralEmoji: null,
      highlightGoodEmoji: null,
      showPremiumPrompt: false,
      premiumPromptText: "",
      isShowPremiumPrompt: false,
      totalPages: 0,
      pageNo: 1,
      requiredQuestionCount: 11110,
      lessQuestionsGenerated: false,
      moreQuestionsGenerated: false,
      showPopup: false,
      showEditContentPopUp: false,
      firstEventScroll: false,
      activity_count: 0,
      isQuestionGenerated: false,
      source: "Direct",
      scrollAfterGenerate: false,
      leavePageEvent: false,
      showUpdateProfilePopUp: false,
      source_from: "",
      source_from_other: "",
      profession: "",
      profession_other: "",
      data: {
        contact_number: "",
        country_code: "in",
        dial_code: "",
        formatted_phone_number: "",
      },
      showLoader: false,
      showFeedbackForm: true,
      ratingComment: "",
      showWordLimit: false,
      docPage: "",
      docPageStatement: "All pages are selected.",
      isCustomPages: false,
      isCustomDuration: false,
      startTime: null,
      endTime: null,
      timeInputMsg: null,
      ques_type: null,
      finalques_type: [],
      ques_count: null,
      totalQuestionsFormed: 0,
      showDurationAlert: false,
      timeSeconds: "00:30:00",
      timerSeconds: 30 * 60,
      stripePromise: null,
      stripeClientSecret: "",
      showStripeForm: false,
      stripeKey: process.env.REACT_APP_STRIPE_KEY,
      showAddOn: false,
      addOnText: "",
      addOnCTAText: "",
      freeAddOnSubText: "",
      addOnPlans: [],
      activatePlan: [],
      showTopUp: false,
      showDemoPopUp: false,
      showPreview: false,
      categoryAccordion: false,
    };
    this.setModal = this.setModal.bind(this);
  }

  setModal = () => {
    this.setState({
      showDemoPopUp: true,
      showDownloadPopUp: false,
    });
  };

  calculateTimeLeft = () => {
    // Set the date we're counting down to
    const countDownDate = new Date("Oct 31, 2022 23:59:59").getTime();

    // get today's date
    const today = new Date().getTime();
    // get the difference
    let diff = countDownDate - today;

    let timeLeft = { days: 0, hours: 0, minutes: 0, seconds: undefined };

    if (diff > 0) {
      timeLeft = {
        // math
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((diff % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    //this.handleGenerateQuestion();
  };

  isFutureDate = (idate) => {
    var today = new Date().getTime(),
      idate = idate.split("/");

    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    return today - idate < 0 ? true : false;
  };

  componentDidMount() {
		if (this.props.isAuthenticated) {
		  this.props.dispatch(fetchUser());
		  const refData = localStorage.getItem("refData");
		  if (refData) {
			this.props.history.replace("/plans");
		  } else {
			this.props.history.replace("/generate-questions");
		  }
		}
  }

  componentDidUpdate() {
    window.onclick = () => {
      if (!this.state.firstEventScroll && this.state.activity_count == 1) {
        //trackEvent(("First Activity - Click");
      }
    };

    window.onscroll = (e) => {
      // console.log("Scroll");
      if (
        !this.state.firstEventScroll &&
        this.state.activity_count == 0 &&
        this.state.source == "Direct"
      ) {
        //trackEvent(("First Activity - Scroll");
        this.setState({
          firstEventScroll: true,
        });
      }

      if (this.state.scrollAfterGenerate) {
        //trackEvent(("Scroll After Question Generation");
        this.setState({
          scrollAfterGenerate: false,
        });
      }
      // Detect user scroll to the bottom of the page.
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        const element = document.getElementById("generated_questions");
        if (element && this.state.source == "Direct") {
          //trackEvent(("Scroll Down To Bottom - Generate Question");
        }
      }
    };
  }

  componentWillUnmount() {
    window.onscroll = () => {};
    window.onclick = () => {};
  }

  tabChanged = (e) => {
    this.props.dispatch(setInitialQues());
    this.setState({ currentActiveTab: e });
  };

  redirectToPlansPage = () => {
    this.props.history.push("/plans-bloom");
  };

  checkCategory = () => {
    var category_id = 7;

    if (this.state.questiontypetab === "knowledge") category_id = 7;
    else if (this.state.questiontypetab === "Comprehension") category_id = 8;
    else if (this.state.questiontypetab === "Application") category_id = 9;
    else if (this.state.questiontypetab === "Analysis") category_id = 10;
    else if (this.state.questiontypetab === "Evaluation") category_id = 11;
    else if (this.state.questiontypetab === "Synthesis") category_id = 12;

    return category_id;
  };

  handleCallback = (childData) => {
    let ques_count = {
      easyCount: 0,
      mediumCount: 0,
      hardCount: 0,
      descriptiveCount: 0,
      trueFalseCount: 0,
      fillUpsCount: 0,
    };
    ques_count.easyCount = childData.total_easy_mcq;
    ques_count.mediumCount = childData.total_medium_mcq;
    ques_count.hardCount = childData.total_hard_mcq;
    ques_count.trueFalseCount = childData.total_true_false;
    ques_count.descriptiveCount = childData.total_descriptive;
    ques_count.fillUpsCount = childData.total_fill_ups;

    var que_obj = childData.questions;
    this.setState({
      questions: [...que_obj],
      ques_count: { ...ques_count },
      pageNo: childData.page_no,
      totalPages: childData.page_no,
    });
  };

  onInputChange = (e) => {
    this.setState({ tmpselectedWikiTopic: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.getContent();
    }
  };

  getContent = async (val) => {
    if (this.state.tmpselectedWikiTopic != "") {
      this.props.dispatch(startLoader());
      try {
        await this.sleep(1000);
        this.setState({ showDemoPopUp: true });
      } catch (err) {
        console.log(err);
        // notifyError(MSG.commonApiError);
      }
      this.props.dispatch(stopLoader());
    } else {
      notifyError("Please enter topic to search");
    }
  };

  handleWikiTopicSelect = async (name, option) => {
    this.setState({
      selectedWikiTopic: option,
      tmpselectedWikiTopic: option,
      [name]: option.value,
    });
  };

  handleWikiTopicFocus = () => {
    this.setState({
      tmpselectedWikiTopic: null,
      options: [],
      activity_count: this.state.activity_count + 1,
    });
  };

  handleWikiTopicBlur = () => {
    if (this.state.tmpselectedWikiTopic == null) {
      this.setState({
        tmpselectedWikiTopic: this.state.selectedWikiTopic,
        options: [],
      });
    }
  };

  handleGenerateQuestionForWiki = async () => {
    this.props.dispatch(startLoader());
    await this.sleep(1000);
    this.setState({ showDemoPopUp: true });

    this.props.dispatch(stopLoader());
  };

  resetQuestionState = () => {
    this.setState({
      questions: [],
      questionPaperGenerated: false,
      ques_count: {
        easyCount: 0,
        mediumCount: 0,
        hardCount: 0,
        descriptiveCount: 0,
        trueFalseCount: 0,
        fillUpsCount: 0,
      },
    });
  };

  scrollToQuestions = () => {
    const position = document.getElementById("generated_questions").offsetTop;
    window.scrollTo(0, position);
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  handleAddQuestionOnClick = () => {
    this.addquestion();
    this.scrollToBottom();
  };

  countWords = (str) => {
    const arr = str.split(" ");
    return arr.filter((word) => word !== "").length;
  };

  handleGenerateQuestion = async (e) => {
    this.props.dispatch(startLoader());
    let question = {
      questions: [
        {
          question_id: 223096,
          content_id: 5870,
          topic: "test",
          category_type: 7,
          question: "How old was Nelson Mandela when he left his home?",
          is_required: 1,
          question_rating: 0,
          option_rating: 0,
          options: ["20 years", "14 years*", "25 years", "18 years"],
          pro_tips: null,
          more_options: "''",
          comment: null,
        },
        {
          question_id: 223101,
          content_id: 5870,
          topic: "test",
          category_type: 8,
          question: "What was the main struggle of Nelson Mandela?",
          is_required: 1,
          question_rating: 0,
          option_rating: 0,
          options: [
            "To take revenge against white people.",
            "To defeat apartheid and create democracy in South Africa.*",
            "To gain power by force.",
            "To fight against the white people.",
          ],
          pro_tips: null,
          more_options: "''",
          comment: null,
        },
        {
          question_id: 223112,
          content_id: 5870,
          topic: "test",
          category_type: 10,
          question: "How did Nelson Mandela’s life story inspire people?",
          is_required: 1,
          question_rating: 0,
          option_rating: 0,
          options: [
            "Nelson Mandela's life story inspired people through his unwavering struggle against apartheid, his commitment to democracy, his ability to forgive and seek cooperation rather than revenge, and his successful efforts to bring about political change. His actions and principles made him a heroic figure who influenced many, including world leaders like President Barack Obama, and he ultimately played a pivotal role in ending apartheid and establishing democracy in South Africa.",
          ],
          pro_tips: null,
          more_options: "''",
          comment: null,
        },
        {
          question_id: 223114,
          content_id: 5870,
          topic: "test",
          category_type: 11,
          question: "Do you agree that Mandela was a great leader? Why?",
          is_required: 1,
          question_rating: 0,
          option_rating: 0,
          options: [
            "Yes, Mandela was a great leader due to his strong character, wise approach, strategic planning, cooperation with white leaders, and successful struggle against apartheid, which inspired millions and led to the establishment of democracy in South Africa.",
          ],
          pro_tips: null,
          more_options: "''",
          comment: null,
        },
      ],
      page_no: 1,
      perPage: 10,
      total_records: 1,
      expected_records: null,
      total_generated_questions: 4,
      total_knowledge: 1,
      total_comprehension: 1,
      total_application: 0,
      total_analysis: 1,
      total_evaluation: 1,
      total_synthesis: 0,
    };

    setTimeout(() => {
      if (localStorage.getItem("hit") == 1) {
        this.setState({ showDemoPopUp: true });
        this.props.dispatch(stopLoader());
        return;
      }
      localStorage.setItem("hit", 1);
      this.setState({
        questions: question.questions,
        questionPaperGenerated: true,
        ques_count: {
          knowledgeCount: question.total_knowledge,
          comprehensionCount: question.total_comprehension,
          applicationCount: question.total_application,
          analysisCount: question.total_analysis,
          evaluationCount: question.total_evaluation,
          synthesisCount: question.total_synthesis,
        },
      });

      notifySuccess("Questions Generated Successfully.");
      this.props.dispatch(stopLoader());
      this.scrollToQuestions();
    }, [1000]);
  };

  //GET Timestamp for the video files
  startTimeStampHandler = (secs) => {
    this.setState({ startTime: secs });
  };

  endTimeStampHandler = (secs) => {
    this.setState({ endTime: secs });
  };

  isCustomDurationHandler = () => {
    this.setState({ isCustomDuration: true });
  };

  startTypingTime = () => {
    if (this.state.timeInputMsg) {
      this.setState({ timeInputMsg: null });
    }
  };

  handleClearCustomDuration = () => {
    this.setState({
      isCustomDuration: false,
      timeInputMsg: null,
      startTime: null,
      endTime: null,
    });
  };

  validateTimeInput = () => {
    const { startTime, endTime } = this.state;
    if (!startTime || !endTime) {
      return true;
    } else if (startTime === endTime) {
      this.setState({ timeInputMsg: "Start & End time can't be same" });
      return false;
    } else if (startTime > endTime) {
      this.setState({ timeInputMsg: "End time can't be less than Start time" });
      return false;
    }
    return true;
  };

  getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        var fileObj = chosenFiles.map((f) => f.fileObject);
        this.setState({ videoFile: fileObj[0], isUploaded: true });
      });
    });
  };

  handleFileStatusChange = ({ meta, file }, status) => {
    if (status == "removed") {
      this.setState({ videoFile: null, isUploaded: false });
    }
  };

  getDocPdfFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        var fileObj = chosenFiles.map((f) => f.fileObject);
        this.setState({ pdfDocFile: fileObj[0] });
      });
    });
  };

  handlePdfDocFileStatusChange = ({ meta, file }, status) => {
    if (status == "removed") {
      this.setState({ pdfDocFile: null });
    }
  };

  validateTopicInput = () => {
    if (_.trim(this.state.titleofQuestion) == "") {
      this.setState(
        {
          titleofQuestion_emsg: "Please enter Chapter name, Any topic, etc.",
        },
        () => {
          const position = document.getElementById("titleofQuestion").offsetTop;
          window.scrollTo(0, position);
          document.getElementById("titleofQuestion").focus();
        }
      );
      return false;
    }
    this.setState({
      titleofQuestion_emsg: "",
    });
    return true;
  };

  scrollToBottom = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  videoExtractButtonContent = () => {
    if (this.props.user.plan_details) {
      return (
        <React.Fragment>
          <React.Fragment>
            {this.props.user.credits <= 1 &&
            !this.props.user.plan_details.is_new_pricing ? (
              <Link type="button" className="btn--submit" to="/plans-bloom">
                Buy Credits
              </Link>
            ) : (
              <button
                type="button"
                className="btn--submit"
                onClick={this.handleGenerateQuestionFromVideo}
                disabled={
                  this.state.videourl || this.state.isUploaded ? false : true
                }
              >
                Extract Content
              </button>
            )}
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  handleGenerateQuestionFromVideo = async () => {
    this.props.dispatch(startLoader());
    await this.sleep(1000);
    this.setState({ showDemoPopUp: true });

    this.props.dispatch(stopLoader());
  };

  // To show custom page input field
  customPageHandler = () => {
    this.setState({ isCustomPages: true });
  };

  // handle
  handleClearCustomPages = () => {
    this.setState({ docPage: "", isCustomPages: false });
  };

  //  Set value for page input and validation only accepts (1,2,6-7,8 kind of values)
  handleDocPage = (event) => {
    // To Check if user have pressed backspace and delete the value
    const pressedBackSpace =
      event.nativeEvent.inputType === "deleteContentBackward";
    if (pressedBackSpace) {
      this.setState((prevValue) => {
        let previousPages = prevValue.docPage;
        if (previousPages) {
          previousPages = previousPages.substr(0, previousPages.length - 1);
        }
        return { docPage: previousPages };
      });
      return;
    }

    // regex to check if entered values are only numbers commas and hyphens
    const regex = /^[\d,-]+$/;
    let value = event.target.value;

    if (value.startsWith("-") || value.startsWith(",")) {
      return;
    }
    if (value.trim() !== "") {
      if (!pressedBackSpace && value.length > 200) {
        return;
      }
      if (value.endsWith("--")) {
        const lastIndex = value.lastIndexOf("-");
        const replacement = "";
        const replaced =
          value.slice(0, lastIndex) + replacement + value.slice(lastIndex + 1);
        this.setState({ docPage: replaced });
      } else if (value.endsWith(",,")) {
        const lastIndex = value.lastIndexOf(",");
        const replacement = "";
        const replaced =
          value.slice(0, lastIndex) + replacement + value.slice(lastIndex + 1);
        this.setState({ docPage: replaced });
      } else if (value.endsWith(" ")) {
        this.setState((prevValue) => {
          let lastvalue = prevValue.docPage;
          if (lastvalue.endsWith(",") || lastvalue.endsWith("-")) {
            return;
          }
          const replaced = lastvalue + ",";
          return { docPage: replaced };
        });
      } else if (value.endsWith("-") || value.endsWith(",")) {
        this.setState((prevValue) => {
          let lastvalue = prevValue.docPage;
          if (lastvalue.endsWith(",") || lastvalue.endsWith("-")) {
            return;
          } else if (lastvalue.length > 1 && value.endsWith("-")) {
            const pattern = /\d+$/;
            let matches = lastvalue.match(pattern);
            if (
              lastvalue.charAt(lastvalue.length - (matches[0].length + 1)) ===
              "-"
            ) {
              return;
            }
          }
          return { docPage: value };
        });
      } else {
        if (regex.test(value)) {
          this.setState({ docPage: value });
        }
      }
    } else {
      this.setState({
        docPage: "",
        docPageStatement: "All pages are selected.",
      });
    }
  };
  // To remove - or , if any in the end
  getFinalPageList = () => {
    const docPage = this.state.docPage;
    if (!_.isEmpty(docPage)) {
      if (docPage.endsWith("-") || docPage.endsWith(",")) {
        let newDocPage = docPage.substr(0, docPage.length - 1);
        this.setState({ docPage: newDocPage });
      }
    }
  };

  handleGenerateQuestionFromPdfDoc = async () => {
    this.props.dispatch(startLoader());
    await this.sleep(1000);
    this.setState({ showDemoPopUp: true });
    this.props.dispatch(stopLoader());
  };

  handleChange = ({ currentTarget: input }) => {
    if (this.state.showWordLimit) this.setState({ showWordLimit: false });
    this.setState({
      [input.name]: input.value,
    });
  };

  handleTitleChange = ({ currentTarget: input }) => {
    if (_.trim(input.value) != "") {
      this.setState({
        titleofQuestion_emsg: "",
      });
    }
    this.setState({
      [input.name]: input.value,
    });
  };

  handleQuestionUpdate = (question) => {
    // console.log(question);
    this.setState(
      produce(this.state, (draftState) => {
        const index = draftState.questions.findIndex(
          (m) => m.question_id === question.question_id
        );
        draftState.questions[index] = question;
      })
    );
  };

  handlestateforaddquestion = (newquestion) => {
    switch (newquestion.category_type) {
      case 1:
        this.state.ques_count.easyCount += 1;
        break;
      case 2:
        this.state.ques_count.mediumCount += 1;
        break;
      case 3:
        this.state.ques_count.hardCount += 1;
        break;
      case 4:
        this.state.ques_count.trueFalseCount += 1;
        break;
      case 5:
        this.state.ques_count.descriptiveCount += 1;
        break;
      case 6:
        this.state.ques_count.fillUpsCount += 1;
        break;
      default:
        break;
    }
    this.setState({
      questions: [...this.state.questions, newquestion],
    });
  };

  addquestion = () => {
    // console.log("op", this.state.questiontypetab);
    //trackEvent(("Add Question Button Clicked", {
    //   category: this.state.questiontypetab,
    //   source: this.state.source,
    // });

    switch (this.state.questiontypetab) {
      case "knowledge":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "Comprehension":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "Application":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "Analysis":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "Evaluation":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "Synthesis":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handlediscard = (questiontype) => {
    switch (questiontype) {
      case "knowledge":
        this.setState({ addeasymcq: !this.state.addeasymcq });
        break;
      case "Comprehension":
        this.setState({ addmediummcq: !this.state.addmediummcq });
        break;
      case "Application":
        this.setState({ addhardmcq: !this.state.addhardmcq });
        break;
      case "Analysis":
        this.setState({ addtruefalse: !this.state.addtruefalse });
        break;
      case "Evaluation":
        this.setState({ adddescriptive: !this.state.adddescriptive });
        break;
      case "Synthesis":
        this.setState({ addfillups: !this.state.addfillups });
        break;
    }
  };

  handleQPRating = async () => {
    if (this.state.questionPaperRating == null) {
      notifyError("Please Rate Question Paper First!");
      return false;
    }
    this.setState({ showRatingAlert: false, feedbackAlert: false });

    var QP_Rating_Parm = {};

    var content_id = this.state.questions[0].content_id;
    if (this.state.ratingComment != "") {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
        comment: this.state.ratingComment,
      };
    } else {
      QP_Rating_Parm = {
        content_id: content_id,
        rating: this.state.questionPaperRating,
      };
    }
    this.props.dispatch(startLoader());
    try {
      const { data: res } = await QuestionPaperRating(QP_Rating_Parm);
      if (res.success == true) {
        notifySuccess(res.message);
        this.setState({
          questionPaperRating: null,
          highlightBadEmoji: null,
          highlightNeutralEmoji: null,
          highlightGoodEmoji: null,
          showFeedbackForm: false,
        });
        this.handleRatingPromptCloseEvent();
        //this.setState({ showRatingAlert: false, feedbackAlert: false });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else if (err.response && err.response.status === 401) {
        const { data } = err.response;
        notifyError(data.msg);
        this.props.dispatch(authLogout());
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.props.dispatch(stopLoader());
  };

  handleQPRatingChange = (value) => {
    this.setState({
      questionPaperRating: value,
    });
  };

  getRateText = (rate) => {
    if (rate == 1) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Bad";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 2) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Neutral";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else if (rate == 3) {
      document.getElementById("labelFeedbackLabel").innerHTML = "Good";
      document.getElementById("labelFeedbackLabel").style.width = "120px";
    } else {
      document.getElementById("labelFeedbackLabel").innerHTML = "";
      document.getElementById("labelFeedbackLabel").style.width = "0";
    }
  };

  handleRatingPromptCloseEvent = () => {
    this.setState({ showRatingAlert: false, feedbackAlert: false });
    if (this.state.isShowPremiumPrompt) {
      this.setState({
        showPremiumPrompt: true,
      });
    }
  };

  handlePagination = async (req) => {
    this.props.dispatch(startLoader());
    this.props.dispatch(stopLoader());
  };

  nextPage = () => {
    if (this.state.pageNo == this.state.totalPages) {
      return;
    }
    var pageNo = this.state.pageNo + 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      category_id: que_obj.category_type,
      page_no: pageNo,
      taxanomy: 1,
    };

    this.handlePagination(req);
  };

  previousPage = () => {
    if (this.state.pageNo == 1) {
      return;
    }
    var pageNo = this.state.pageNo - 1;
    var que_obj = this.state.questions[0];

    var req = {
      content_id: que_obj.content_id,
      category_id: que_obj.category_type,
      page_no: pageNo,
      taxanomy: 1,
    };
    this.handlePagination(req);
  };

  handleQueTypeClick = (e, quetype, category) => {
    e.preventDefault();
    const ques_count = this.state.ques_count;
    if (category == 7) {
      //trackEvent(("Easy MCQ Category Clicked");
      if (ques_count.knowledgeCount == 0) {
        this.setState({ showDemoPopUp: true });
        // notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 8) {
      //trackEvent(("Medium MCQ Category Clicked");
      if (ques_count.comprehensionCount == 0) {
        this.setState({ showDemoPopUp: true });
        // notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 9) {
      //trackEvent(("Hard MCQ Category Clicked");
      if (ques_count.applicationCount == 0) {
        this.setState({ showDemoPopUp: true });
        // notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 10) {
      //trackEvent(("True/False Category Clicked");
      if (ques_count.analysisCount == 0) {
        this.setState({ showDemoPopUp: true });
        // notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 11) {
      //trackEvent(("Descriptive Category Clicked");
      if (ques_count.evaluationCount == 0) {
        this.setState({ showDemoPopUp: true });
        // notifySuccess("No Questions Formed");
        return;
      }
    } else if (category == 12) {
      //trackEvent(("Fillups Category Clicked");
      if (ques_count.synthesisCount == 0) {
        this.setState({ showDemoPopUp: true });
        // notifySuccess("No Questions Formed");
        return;
      }
    }
    this.setState({ questiontypetab: quetype });
    var que_obj = this.state.questions[0];
    var req = {
      content_id: que_obj.content_id,
      category_id: category,
      page_no: 1,
      taxanomy: 1,
    };
    this.handlePagination(req);
  };

  // Showing the filters selected

  showSelectedFilter = () => {
    return (
      <div className="selected_filters">
        <p className="selected_filter rounded shadow">Easy MCQ</p>
        <p className="selected_filter rounded shadow">Easy MCQ</p>
        <p className="selected_filter rounded shadow">Easy MCQ</p>
      </div>
    );
  };

  // For testing Referral Popup
  // handlePopupOpen = () => {
  //   this.props.dispatch(
  //     showReferral({
  //       title: "Want to extend your plan for free?",
  //       content: "Invite your friends and get 7 days of Premium Plan added",
  //     })
  //   );
  // };

  handleClose = () => {
    this.props.dispatch(closeReferral());
  };

  updateActivityCount = (e) => {
    if (e.target.className == "dzu-input") {
      var increment = this.state.activity_count + 1;
      this.setState({
        activity_count: increment,
      });
    }
  };

  handleOnChange = (value, data, event, formattedValue) => {
    if (value !== undefined) {
      this.setState({
        data: {
          ...this.state.data,
          contact_number: value,
          formatted_phone_number: formattedValue,
          country_code: data.countryCode,
          dial_code: "+" + data.dialCode,
        },
      });
    }
  };

  handleUpdateProfileChange = ({ currentTarget: input }) => {
    this.setState({
      [input.name]: input.value,
    });
  };

  UpdateProfileSubmit = async () => {
    let req = {};
    if (this.props.user.update_profile_index == 1) {
      if (this.state.profession == "") {
        notifyError("Please select a option");
        return;
      }
      if (
        this.state.profession == "Others" &&
        this.state.profession_other == ""
      ) {
        notifyError("Please enter your profession");
        return;
      }

      req = {
        profession: this.state.profession,
        profession_other: this.state.profession_other,
      };
    } else if (this.props.user.update_profile_index == 2) {
      if (this.state.source_from == "") {
        notifyError("Please select a option");
        return;
      }
      if (
        this.state.source_from == "Others" &&
        this.state.source_from_other == ""
      ) {
        notifyError("Please enter your profession");
        return;
      }

      req = {
        source_from: this.state.source_from,
        source_from_other: this.state.source_from_other,
      };
    } else if (this.props.user.update_profile_index == 3) {
      if (this.state.data.contact_number == "") {
        notifyError("Please enter your phone details");
        return;
      }

      var reqObj = this.state.data;
      req = { ...reqObj };
      req.contact_number = req.contact_number.substring(
        req.dial_code.length - 1
      );
    }

    this.setState({
      showLoader: !this.state.showLoader,
    });

    try {
      const { data: res } = await updateUserDetails(req);
      if (res.success == true) {
        await this.props.dispatch(fetchUser());
        if (this.props.user.update_profile_index == 0) {
          notifySuccess(res.message);
          this.setState({
            showUpdateProfilePopUp: false,
          });
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
  };

  OpenReviewPopUp = () => {
    this.setState({
      showRatingAlert: true,
    });
  };

  handleRatingComment = (e) => {
    this.setState({
      ratingComment: e.target.value,
    });
  };

  beforeAssessment = () => {
    const { content_id, topic } = this.state.questions[0];
    this.setState({
      showDurationAlert: true,
      currentContentId: content_id,
      currentTopicName: topic,
    });
  };

  callbackFunc = (topic, show) => {
    this.setState({ showDurationAlert: false, currentTopicName: topic });
  };

  handleCreateTest = async () => {
    if (!this.props.user.plan_details.is_group_assessment) {
      return;
    }
    this.props.dispatch(startLoader());
    var content_id = this.state.questions[0].content_id;
    try {
      const { data: res } = await createTest({
        content_id: content_id,
      });
      if (res.success == true) {
        notifySuccess(res.message);
        this.props.history.push({
          pathname: "/test-preview-bloom",
          state: {
            content_id: content_id,
            test_id: res.response.test_id,
          },
        });
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      notifyError(MSG.commonApiError);
    }
    this.props.dispatch(stopLoader());
  };
  handleGenerateAnyway = () => {
    this.setState({ newUser: false, showEditContentPopUp: false }, () =>
      this.handleGenerateQuestion()
    );
    this.props.dispatch(
      showDefaultContent({
        title: "",
        content: ``,
        flag: true,
      })
    );
  };

  handleUpdateContent = () => {
    this.setState({
      newUser: false,
      showEditContentPopUp: false,
      titleofQuestion: "",
      contentForQuestions: "",
    });
    const position = document.getElementById("titleofQuestion").offsetTop;
    window.scrollTo(0, position);
    document.getElementById("titleofQuestion").focus();
    this.props.dispatch(
      showDefaultContent({
        hotsTitle: "",
        hotsContent: ``,
        hotsFlag: true,
      })
    );
  };

  displayStripe = async (plan) => {
    if (plan.plan_name === "Free") {
      this.props.history.replace("/plans");
      return;
    }

    this.setState({
      showPrompt: false,
      showEnterprisePrompt: false,
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(startLoader());

    try {
      let req = {
        add_on_id: plan.plan_id,
        // team_size: this.state.team_size,
      };

      const { data: res } = await purchaseAddOns(req);
      if (res.success == true) {
        notifySuccess(res.message);
        // this.props.dispatch(setSelectedPlan(plan));
        const clientSecret = res.response.clientSecret;
        var payment_details = {
          clientSecret: clientSecret,
          amount: res.response.amount,
          is_coupon_applied: res.response.is_coupon_applied,
          coupon: res.response.coupon,
          currency: res.response.currency,
          // price_usd: plan.price,
          // plan_name: plan.plan_name,
          // currency:
          //   this.state.plans["Monthly"][this.state.questionPaperCountSolo][
          //     "currency"
          //   ],
          // team_size: this.state.team_size,
        };
        this.props.dispatch(setSelectedPlan(payment_details));
        if (clientSecret) {
          this.setState({
            // selectedPlan: { ...plan },
            stripePromise: loadStripe(this.state.stripeKey),
            showStripeForm: true,
            stripeClientSecret: clientSecret,
            showAddOn: false,
          });
          // this.props.dispatch(
          //   showReferral({
          //     title: "Want to extend your plan for free?",
          //     content:
          //       "Invite your friends and get 7 days of Premium Plan added",
          //   })
          // );
        }
      } else {
        notifyError(res.message);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 422) {
        const { data } = err.response;
        notifyError(data.message);
      } else {
        notifyError(MSG.commonApiError);
      }
    }
    this.setState({
      showLoader: !this.state.showLoader,
    });
    this.props.dispatch(stopLoader());
  };

  questionPaperTopUPCallBack = (data) => {
    if (data.hidePopUp) {
      this.setState({
        showTopUp: !data.hidePopUp,
      });
    }
  };

  showHeaderTextPlan = () => {
    if (this.props.user.plan_details) {
      var planButtonText = "Upgrade Plan";

      if (this.props.user.plan_details.plan_name == "Yearly") {
        var planButtonText = "View Plan";
      }
      return (
        <React.Fragment>
          <React.Fragment>
            <li className="nav-item">
              {/* Get the most of PrepAI with{" "}
                <Link className="nav-link text-success-prep ml-1" to="/plans">
                  Premium Plans
                </Link> */}
              <Link className="btn upgrade-plan-btn" to="/plans">
                {planButtonText}
              </Link>
            </li>
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  handlePopUpClose = () => {
    this.setState({
      showDemoPopUp: false,
    });
  };

  toggleDefaultActiveTab = (state) => {
    this.setState(state);
  };

  render() {
    let que_number_start_pt = this.state.pageNo * 10 - 10; // ques number for first question of every page say 1 for page one 11 for page 2
    let easymcq = que_number_start_pt;
    let mediummcq = que_number_start_pt;
    let hardmcq = que_number_start_pt;
    let true_false = que_number_start_pt;
    let fillups = que_number_start_pt;
    let descriptive = que_number_start_pt;
    let is_new_pricing = 1;

    return (
      <React.Fragment>
        <DemoHeader
          handlePopUpOpen={this.handlePopUpOpen}
          showHeaderTextPlan={this.showHeaderTextPlan}
          props={this.props}
        />
        <div style={{ top: "80px", position: "relative" }}>
          <Salebar></Salebar>
        </div>

        <div>
          <Dialog
            id="free-dialog-box"
            open={this.state.showDemoPopUp}
            onClose={this.handlePopUpClose}
          >
            <DialogTitle
              style={{
                textAlign: "center",
              }}
            >
              <img
                src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
                className={classNames(styles["demo-popup-logo"])}
                style={{
                  height: "200px",
                  width: "250px",
                  objectFit: "contain",
                  objectPosition: "30% 30%",
                  zIndex: "1",
                }}
                alt="prepAi-logo"
              />
            </DialogTitle>

            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px",
                zIndex: "5",
                marginTop: "-65px",
                marginBottom: "25px",
              }}
            >
              Start Your 15-day Free Trial
            </Typography>
            <Typography>
              <div
                style={{
                  textAlign: "center",
                  margin: "5px 10px 20px",
                  fontSize: "13px",
                }}
              >
                <Typography variant="h7" style={{ marginBottom: "20px" }}>
                  Generate assessments with AI, at no cost, today!
                </Typography>
                {/* <br />
                <Typography variant="h7">
                  Register on PrepAI and get started.
                </Typography> */}
              </div>
            </Typography>

            <Typography>
              <div style={{ textAlign: "center", lineHeight: "10px" }}>
                <Link to={`/register${this.props.location.search}`}>
                  <p className="btn upgrade-plan-btn">Sign Up Now </p>
                </Link>
                <p style={{ fontSize: "12px", color: "gray" }}>
                  (No CC required)
                </p>
              </div>
            </Typography>

            <Typography>
              <Link to={`/login${this.props.location.search}`}>
                <div style={{ textAlign: "center", fontSize: "14px" }}>
                  <p>Already have an account?</p>
                </div>
              </Link>
            </Typography>
          </Dialog>
        </div>

        <Tooltip title="Coming Soon" id="global" />
        <div
          className={classNames(
            styles["mobile-height-cover"],
            "bg-e5"
          )}
          style={{overflowY: "auto"}}
        >
          <div className="bg--overlay--top"></div>
          <div
            className={classNames(
              "container",
              styles["demo-custom-margin"],
              "mb-4 position-relative"
            )}
          >
            <div
              className={classNames(
                styles["generate--bg--title"],
                "generate--bg--title"
              )}
            >
              <h3>Generate Higher Order Thinking Questions</h3>
              <span>Based on Bloom's Taxonomy</span>
            </div>
            <ToggleForGQ tab="HOTS" />
            <DemoGenerateQuestionsStdHots
              handleSubmit={this.handleSubmit}
              state={this.state}
              handleTitleChange={this.handleTitleChange}
              handleChange={this.handleChange}
              props={this.props}
              handlePdfDocFileStatusChange={this.handlePdfDocFileStatusChange}
              handlePopUpOpen={this.handlePopUpOpen}
              handleWikiTopicBlur={this.handleWikiTopicBlur}
              handleWikiTopicFocus={this.handleWikiTopicFocus}
              onInputChange={this.onInputChange}
              getFilesFromEvent={this.getFilesFromEvent}
              handleFileStatusChange={this.handleFileStatusChange}
              handleGenerateQuestions={this.handleGenerateQuestion}
              handlePopUpClose={this.handlePopUpClose}
              handleWikiTopicSelect={this.handleWikiTopicSelect}
              videoExtractButtonContent={this.videoExtractButtonContent}
              getDocPdfFromEvent={this.getDocPdfFromEvent}
              setState={this.toggleDefaultActiveTab}
            />
          </div>
        </div>

        {this.state.questionPaperGenerated && (
          <div className="section--padding bg-e5" id="generated_questions">
            <div
              className={classNames(
                "container",
                styles["demo-custom-container"]
              )}
            >
              <div className="row">
                <div
                  className="offset-md-1"
                  style={{ width: "100%", marginLeft: "1.333333%" }}
                >
                  <div
                    className="offset-md-1 mb-4"
                    style={{ width: "100%", marginLeft: "1.333333%" }}
                  >
                    <div
                      className={classNames(
                        styles["generated-questions-top-section"],
                        "row"
                      )}
                    >
                      <div className="col-md-4">
                        <p
                          className={classNames(
                            styles["section-generated-title-responsive"],
                            "section-generated-title"
                          )}
                          // style={{ marginLeft: "29.5%" }}
                        >
                          Generated Questions
                        </p>
                      </div>
                      <div
                        className={classNames(
                          styles["front-action-buttons-responsive"],
                          "col-md-8 front-action-buttons"
                        )}
                      >
                        <div className="nav-item dropdown fl-center">
                          <a
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              "nav-link dropdown-toggle dropdown-title-front"
                            )}
                            href="/#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Conduct Test
                          </a>
                          <div
                           className={classNames(
                            styles["conduct-test-dropdown"],
                            "dropdown-menu navdrop--menu dropdown-main"
                          )}
                            aria-labelledby="navbarDropdown"
                          >
                            <button
                              // to="#"
                              onClick={() =>
                                this.setState({ showDemoPopUp: true })
                              }
                              className={classNames(
                                styles["front-action-buttons-text-responsive"],
                                "dropdown-item"
                              )}
                            >
                              For Self
                            </button>
                            <button
                              // to="#"
                              onClick={() =>
                                this.setState({ showDemoPopUp: true })
                              }
                              className={classNames(
                                styles["front-action-buttons-text-responsive"],
                                "dropdown-item"
                              )}
                            >
                              For Others
                              {is_new_pricing === 1 && (
                                <img
                                  src="img/crown.jpg"
                                  alt="icon"
                                  width="30"
                                  height="30"
                                  style={{
                                    position: "absolute",
                                    marginTop: "-12px",
                                    marginLeft: "-7px",
                                  }}
                                />
                              )}
                            </button>
                            {/* <button
                          className="dropdown-item"
                          to={{
                            pathname: "/question-paper-preview-bloom",
                            state: this.state.questions[0],
                          }}
                          onClick={(e) =>
                            trackEvent("Preview Question Paper Clicked", {
                              source: this.state.source,
                            })
                          }
                        >
                          Preview Question Paper
                        </button> */}
                          </div>
                        </div>
                        <div className="">
                          <button
                            type="button"
                            role="button"
                            style={{ textDecoration: "none" }}
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              "preview--paper--btn"
                            )}
                            // to="#"
                            onClick={() => this.setState({ showPreview: true })}
                          >
                            Preview Quiz
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            role="button"
                            style={{ textDecoration: "none" }}
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              "preview--paper--btn"
                            )}
                            // to={"#"}
                            onClick={(e) => {
                              this.setState({ showDemoPopUp: true });
                            }}
                          >
                            Generate Sets
                          </button>
                        </div>
                        <div>
                          <button
                            type="button"
                            role="button"
                            style={{ textDecoration: "none" }}
                            className={classNames(
                              styles["front-action-buttons-text-responsive"],
                              "preview--paper--btn"
                            )}
                            // to={"#"}
                            onClick={(e) => {
                              this.setState({ showDownloadPopUp: true });
                            }}
                          >
                            Download
                          </button>
                          <DonwloadQuestionPaper
                            isDemo={true}
                            setDemoModal={this.setModal}
                            showDownloadPopUp={this.state.showDownloadPopUp}
                            closeDownloadPopUp={() =>
                              this.setState({ showDownloadPopUp: false })
                            }
                            downloadAllowed={this.state.currentItem}
                            downloadQuestionPaper={this.handleDownloadFile}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="left-widget-area">
                    <div
                      className={classNames(
                        styles["category-widget-responsive"],
                        "category-widget"
                      )}
                    >
                      <p
                        className={classNames(styles["categories-title"])}
                        style={{
                          marginBottom:
                            window.innerWidth > 767
                              ? "1rem"
                              : this.state.categoryAccordion
                              ? "1rem"
                              : "0rem",
                        }}
                      >
                        Levels
                        {window.innerWidth <= 767 && (
                          <>
                            {this.state.categoryAccordion ? (
                              <ExpandLess
                                onClick={() =>
                                  this.setState({
                                    categoryAccordion:
                                      !this.state.categoryAccordion,
                                  })
                                }
                              />
                            ) : (
                              <ExpandMore
                                onClick={() =>
                                  this.setState({
                                    categoryAccordion:
                                      !this.state.categoryAccordion,
                                  })
                                }
                              />
                            )}
                          </>
                        )}
                      </p>
                      {window.innerWidth <= 767 &&
                        this.state.categoryAccordion && (
                          <div className="custom-nav-pills-column">
                            <div
                              className="nav flex-column nav-pills"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <a
                                className={classNames(
                                  styles["category-nav-link"],
                                  "nav-link active"
                                )}
                                id="v-pills-easy-tab"
                                data-toggle="pill"
                                href="#v-pills-easy"
                                role="tab"
                                aria-controls="v-pills-easy"
                                aria-selected="true"
                                onClick={(e) =>
                                  this.handleQueTypeClick(e, "knowledge", 7)
                                }
                              >
                                Knowledge
                                <span>
                                  {this.state.ques_count.knowledgeCount}
                                </span>
                              </a>
                              <a
                                className={classNames(
                                  styles["category-nav-link"],
                                  "nav-link"
                                )}
                                id="v-pills-medium-tab"
                                data-toggle="pill"
                                href="#v-pills-medium"
                                role="tab"
                                aria-controls="v-pills-medium"
                                aria-selected="false"
                                onClick={(e) =>
                                  this.handleQueTypeClick(e, "Comprehension", 8)
                                }
                              >
                                Comprehension
                                <span>
                                  {this.state.ques_count.comprehensionCount}
                                </span>
                              </a>
                              <a
                                className={classNames(
                                  styles["category-nav-link"],
                                  "nav-link"
                                )}
                                id="v-pills-hard-tab"
                                // data-toggle="pill"
                                href="#v"
                                role="tab"
                                aria-controls="v-pills-hard"
                                aria-selected="false"
                                onClick={(e) =>
                                  this.handleQueTypeClick(e, "Application", 9)
                                }
                              >
                                Application
                                <span>
                                  {this.state.ques_count.applicationCount}
                                </span>
                              </a>
                              <a
                                className={classNames(
                                  styles["category-nav-link"],
                                  "nav-link"
                                )}
                                id="v-pills-truefalse-tab"
                                data-toggle="pill"
                                href="#v-pills-truefalse"
                                role="tab"
                                aria-controls="v-pills-truefalse"
                                aria-selected="false"
                                onClick={(e) =>
                                  this.handleQueTypeClick(e, "Analysis", 10)
                                }
                              >
                                Analysis
                                <span>
                                  {this.state.ques_count.analysisCount}
                                </span>
                              </a>
                              <a
                                className={classNames(
                                  styles["category-nav-link"],
                                  "nav-link"
                                )}
                                id="v-pills-Descriptive-tab"
                                data-toggle="pill"
                                href="#v-pills-Descriptive"
                                role="tab"
                                aria-controls="v-pills-Descriptive"
                                aria-selected="false"
                                onClick={(e) =>
                                  this.handleQueTypeClick(e, "Evaluation", 11)
                                }
                              >
                                Evaluation
                                <span>
                                  {this.state.ques_count.evaluationCount}
                                </span>
                              </a>
                              <a
                                className={classNames(
                                  styles["category-nav-link"],
                                  "nav-link"
                                )}
                                id="v-pills-Fillups-tab"
                                // data-toggle="pill"
                                href="#v"
                                role="tab"
                                aria-controls="v-pills-Fillups"
                                aria-selected="false"
                                onClick={(e) =>
                                  this.handleQueTypeClick(e, "Synthesis", 12)
                                }
                              >
                                Synthesis
                                <span>
                                  {this.state.ques_count.synthesisCount}
                                </span>
                              </a>
                            </div>
                          </div>
                        )}
                      {window.innerWidth > 767 && (
                        <div className="custom-nav-pills-column">
                          <div
                            className="nav flex-column nav-pills"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                          >
                            <a
                              className={classNames(
                                styles["category-nav-link"],
                                "nav-link active"
                              )}
                              id="v-pills-easy-tab"
                              data-toggle="pill"
                              href="#v-pills-easy"
                              role="tab"
                              aria-controls="v-pills-easy"
                              aria-selected="true"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "knowledge", 7)
                              }
                            >
                              Knowledge
                              <span>
                                {this.state.ques_count.knowledgeCount}
                              </span>
                            </a>
                            <a
                              className={classNames(
                                styles["category-nav-link"],
                                "nav-link"
                              )}
                              id="v-pills-medium-tab"
                              data-toggle="pill"
                              href="#v-pills-medium"
                              role="tab"
                              aria-controls="v-pills-medium"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "Comprehension", 8)
                              }
                            >
                              Comprehension
                              <span>
                                {this.state.ques_count.comprehensionCount}
                              </span>
                            </a>
                            <a
                              className={classNames(
                                styles["category-nav-link"],
                                "nav-link"
                              )}
                              id="v-pills-hard-tab"
                              // data-toggle="pill"
                              href="#v"
                              role="tab"
                              aria-controls="v-pills-hard"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "Application", 9)
                              }
                            >
                              Application
                              <span>
                                {this.state.ques_count.applicationCount}
                              </span>
                            </a>
                            <a
                              className={classNames(
                                styles["category-nav-link"],
                                "nav-link"
                              )}
                              id="v-pills-truefalse-tab"
                              data-toggle="pill"
                              href="#v-pills-truefalse"
                              role="tab"
                              aria-controls="v-pills-truefalse"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "Analysis", 10)
                              }
                            >
                              Analysis
                              <span>{this.state.ques_count.analysisCount}</span>
                            </a>
                            <a
                              className={classNames(
                                styles["category-nav-link"],
                                "nav-link"
                              )}
                              id="v-pills-Descriptive-tab"
                              data-toggle="pill"
                              href="#v-pills-Descriptive"
                              role="tab"
                              aria-controls="v-pills-Descriptive"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "Evaluation", 11)
                              }
                            >
                              Evaluation
                              <span>
                                {this.state.ques_count.evaluationCount}
                              </span>
                            </a>
                            <a
                              className={classNames(
                                styles["category-nav-link"],
                                "nav-link"
                              )}
                              id="v-pills-Fillups-tab"
                              // data-toggle="pill"
                              href="#v"
                              role="tab"
                              aria-controls="v-pills-Fillups"
                              aria-selected="false"
                              onClick={(e) =>
                                this.handleQueTypeClick(e, "Synthesis", 12)
                              }
                            >
                              Synthesis
                              <span>
                                {this.state.ques_count.synthesisCount}
                              </span>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-easy"
                      role="tabpanel"
                      aria-labelledby="v-pills-easy-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.questions.length == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 7) {
                            easymcq = easymcq + 1;
                            return (
                              <BloomsTexanomy
                                key={`question_${question.question_id}`}
                                question={question}
                                count={easymcq}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                data={{ source: this.state.source }}
                                disable={true}
                              />
                            );
                          }
                        })}
                        {this.state.addeasymcq == true ? (
                          <div>
                            <br />
                            <Addeasymcq
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Addeasymcq>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-medium"
                      role="tabpanel"
                      aria-labelledby="v-pills-medium-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.mediumCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 8) {
                            mediummcq = mediummcq + 1;
                            return (
                              <BloomsTexanomy
                                key={`question_${question.question_id}`}
                                question={question}
                                count={mediummcq}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                data={{ source: this.state.source }}
                                disable={true}
                              />
                            );
                          }
                        })}
                        {this.state.addmediummcq == true ? (
                          <div>
                            <br />
                            <Addmediummcq
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Addmediummcq>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-hard"
                      role="tabpanel"
                      aria-labelledby="v-pills-hard-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.hardCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 9) {
                            hardmcq = hardmcq + 1;
                            return (
                              <BloomsTexanomy
                                key={`question_${question.question_id}`}
                                question={question}
                                count={hardmcq}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                data={{ source: this.state.source }}
                                disable={true}
                              />
                            );
                          }
                        })}
                        {this.state.addhardmcq == true ? (
                          <div>
                            <br />
                            <Addhardmcq
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Addhardmcq>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-truefalse"
                      role="tabpanel"
                      aria-labelledby="v-pills-truefalse-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.trueFalseCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 10) {
                            true_false = true_false + 1;
                            return (
                              <BloomsTexanomy
                                key={`question_${question.question_id}`}
                                question={question}
                                count={true_false}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                data={{ source: this.state.source }}
                                disable={true}
                                isDemo={true}
                                setModal={this.setModal}
                                descrptiveAns={true}
                              />
                            );
                          }
                        })}
                        {this.state.addtruefalse == true ? (
                          <div>
                            <br />
                            <Truefalse
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Truefalse>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-Descriptive"
                      role="tabpanel"
                      aria-labelledby="v-pills-Descriptive-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.descriptiveCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 11) {
                            descriptive = descriptive + 1;
                            return (
                              <BloomsTexanomy
                                key={`question_${question.question_id}`}
                                question={question}
                                count={descriptive}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                data={{ source: this.state.source }}
                                disable={true}
                                isDemo={true}
                                setModal={this.setModal}
                                descrptiveAns={true}
                              />
                            );
                          }
                        })}
                        {this.state.adddescriptive == true ? (
                          <div>
                            <br />
                            <AddDescriptive
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                              disable={true}
                            ></AddDescriptive>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-Fillups"
                      role="tabpanel"
                      aria-labelledby="v-pills-Fillups-tab"
                    >
                      <div
                        className={classNames(
                          styles["qna-wrapper"],
                          "question-wrapper--outer"
                        )}
                      >
                        {this.state.ques_count.fillUpsCount == 0 && (
                          <p>No Questions Formed!</p>
                        )}
                        {this.state.questions.map((question) => {
                          if (question.category_type == 12) {
                            fillups = fillups + 1;
                            return (
                              <BloomsTexanomy
                                key={`question_${question.question_id}`}
                                question={question}
                                count={fillups}
                                handleQuestionUpdate={this.handleQuestionUpdate}
                                data={{ source: this.state.source }}
                                disable={true}
                              />
                            );
                          }
                        })}
                        {this.state.addfillups == true ? (
                          <div>
                            <br />
                            <Fillups
                              questions={this.state.questions}
                              handlestate={this.handlestateforaddquestion}
                              parentCallback={this.handleCallback}
                              discardquestion={this.handlediscard}
                            ></Fillups>{" "}
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <SweetAlert
          customClass={classNames(
            styles["swal-preview"],
            "sweet-alert-preview"
          )}
          show={this.state.showPreview}
          showConfirm
          confirmBtnText="Okay"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showPreview: false })}
          onOutsideClick={() => this.setState({ showPreview: false })}
          onConfirm={() => this.setState({ showPreview: false })}
          onCancel={() => this.setState({ showPreview: false })}
        >
          <button
            type="button"
            className={classNames(
              styles["swal-cross-btn"],
              "close sets-standard-close"
            )}
            aria-label="Close"
            style={{ outline: "none" }}
            onClick={() => this.setState({ showPreview: false })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <QuestionPaperPreviewBloom
            location={{
              state: {
                topic: this.state.titleofQuestion,
                content_id: this.state.questions[0]?.content_id,
                ques_count: this.state.ques_count,
              },
            }}
            isModalPreview={true}
            isDemo={true}
          />
        </SweetAlert>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    addOnsList: state.auth.addOnsList,
    showPopup: state.generalActions.showPopUp,
    defaultContent: state.generalActions.defaultContent,
    customQuesType: state.generalActions,
  };
};

export default connect(mapStateToProps)(QuestionPaperGenerateBloom);
